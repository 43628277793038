import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';

import { PDFRow } from '../../components/PDFRow';

import { getPDFPages } from '../../actions/floorPlanActions';

import './styles/PDFImageSelector.css';

export const PDFImageSelector = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [pages, setPages] = useState([]);
    const [error, setError] = useState(null);
    
    const surveySelector = useSelector((state) => state.survey);
    const { floorPlans } = surveySelector;
    
    const floorPlanSelector = useSelector((state) => state.floorPlan);
    const { plans } = floorPlanSelector;

    const handleContinue = () => {
        console.log(plans)
        console.log(pages)
        if (plans) {
            if (Object.values(plans).filter((plan) => plan.active === true).length > 0) {
                dispatch(getPDFPages(pages));
                history.push('/survey/doors');
            } else {
                setError('You must select a page to continue');
            }
        } else {
            setError('You must select a page to continue');
        }
    }

    const handleSetPages = (newPage, selected) => {
        if (selected) {
            setPages([...pages, newPage])
        } else {
            setPages([...pages.filter((page) => page.id !== newPage.id)]);
        }
    }

    useEffect(() => {
        if (floorPlans) {
            if (plans) {
                const tmpPlans = Object.keys(plans).filter((key) => plans[key].active === true);

                let tmpCurrentPlans = [];
                
                tmpPlans.forEach((key) => {
                    tmpCurrentPlans.push(floorPlans.find((floorPlan) => floorPlan.id === key));
                });

                setPages([...tmpCurrentPlans]);
            }
        }
    }, []);

    useEffect(() => {
        console.log(pages);
    }, [pages])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Fragment>
            <Header />
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                <h1 style={{ textAlign: 'center' }}>{floorPlans.length} pages have been discovered.<br />Click the edit button to change the name. Click the selection toggle button to activate / Deactivate.</h1>
                {floorPlans.map((floorPlan, index) => {
                    let label = `Page ${index + 1}`;
                    let selected = false;

                    console.log(plans);
                    if (plans) {
                        const plan = plans[floorPlan.id];

                        if (plan) {
                            label = plan?.label;
                            selected = plan?.active;
                        }
                    }
                    
                    return <PDFRow id={floorPlan?.id} image={floorPlan?.image} label={label} setPages={handleSetPages} previouslySelected={selected} />
                })}
                {error && (
                    <div style={{ textAlign: 'center', marginTop: '1rem' }}>{error}</div>
                )}
                <div className="form__buttons">
                    <button aria-label="Continue" onClick={handleContinue} className="form__button form__button--inline" type="submit">Continue</button>
                </div>
            </Content>
            <BottomNav />
        </Fragment>
    )
}
