import React from 'react';
import Draggable from 'react-draggable';

import useLongPress from '../../hooks/useLongPress';

import './css/FloorPlanPin.css';

const labelFontSize = {
    1: '1rem',
    2: '0.75rem',
    3: '0.5rem',
    4: '0.25rem',
    5: '0.125rem',
    6: '0.1rem',
    7: '0.075rem',
    8: '0.05rem',
}

export const FloorPlanPin = ({ pin, active, disabled, scale, handleStop, setSelectedPin }) => {

    console.log(scale);

    const handleOnClick = () => {
        if (active !== pin.id && disabled) {
            setSelectedPin(pin.id);
        } else {
            setSelectedPin(null);
        }
    }
    
    const longPressEvent = useLongPress(handleOnClick, handleOnClick);

    return (
        <Draggable key={pin.id} position={{ x: pin.position?.x, y: pin.position?.y }} onStop={handleStop} disabled={!disabled || scale > 1}>
            <div id={pin.id} className={`pin ${active === pin.id && disabled && 'active'}`} style={{ cursor: disabled || scale <= 1 ? 'pointer' : 'auto' }} onClick={handleOnClick} onTouchStart={handleOnClick}>
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" /></svg>
                {pin?.reference && (
                    <div className="floorPin--transparent">
                        <span style={{ fontSize: labelFontSize[parseInt(scale)] }}>{pin?.reference}</span>
                    </div>
                )}
            </div>
        </Draggable>
    )
}
