import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as Cross } from '../../assets/cross.svg';
import { ReactComponent as Tick } from '../../assets/check.svg';
import { ReactComponent as Exclamation } from '../../assets/exclamation.svg';

const Select = ({ field: { type, name, label, id, required, class: fieldClass, group }, handleChange, handleDefaultCosts, options, handleScore, defaultValue, errorClass, hide, doorId, defaultHideOptions, hideOptions, setHideOptions, textBefore }) => {
    const costsSelector = useSelector((state) => state.costs);
    const { costs } = costsSelector;
    const savedCost = costs[name];

    const [icon, setIcon] = useState(null);
    const [action, setAction] = useState(null);
    const [style, setStyle] = useState('');
    const [customCost, setCustomCost] = useState(0.00);

    const handleLocalChange = event => {
        handleChange(event);

        const option = options.filter((option) => option.field_option_id === event.target.value)[0];

        setAction(option?.option_description);
        setIcon(option?.icon);
        setStyle(option?.style);
        if (group !== 'custom_questions') {
            handleDefaultCosts(name, savedCost);
        }
        handleScore(name, option?.option_score);
    }

    const handleLocalCostChange = (e) => {
        setCustomCost(e.target.value);
        handleDefaultCosts(name, e.target.value);
    }

    const handleHideOptions = () => {
        setHideOptions(prev => [...prev.filter((option) => option !== name)])
    }

    const renderIcon = () => {
        switch (icon) {
            case 'tick':
                return <Tick />;
            case 'warning':
                return <Exclamation />;
            case 'cross':
                return <Cross />;
            default:
                return;
        }
    }

    useEffect(() => {
        const event = { target: { name, value: defaultValue }};

        const activeSurvey = localStorage.getItem('activeSurvey');
        const doorCosts = JSON.parse(localStorage.getItem('doorCosts'));

        if (doorCosts && doorCosts[activeSurvey]) {
            if (doorCosts[activeSurvey][doorId]) {
                setCustomCost(doorCosts[activeSurvey][doorId][name]);
            }
        }

        handleLocalChange(event)
    }, [defaultValue]);

    return (
        <>
            {textBefore && (<div style={{marginBottom: '1rem' }}>{textBefore}</div>)}
            <div className={`${errorClass} ${fieldClass} ${group === 'custom_questions' ? 'input__container--inline' : ''}`} style={{ display: hide ? 'none' : 'block' }}>
                <label className={`form__label${defaultHideOptions.includes(name) ? ' clickable' : ''}`} onClick={handleHideOptions} style={{ marginRight: '1.75rem' }} htmlFor={id}>{group === 'custom_questions' ? 'Outcome' : label}{required === true && (<span class="text-red">*</span>)}</label>
                    
                    <div className="selectContainer">
                        <select className={`form__select${defaultHideOptions.includes(name) ? ' alternative' : ''}`} id={id} type={type} name={name} onChange={handleLocalChange} onLoad={(e) => handleLocalChange(e)} defaultValue={defaultValue}>
                            <option value="">-- Please select an option --</option>
                            {options && options.map((option) => (
                                <option value={option?.field_option_id}>{option?.option_name}</option>
                            ))}
                        </select>
                        <div className="actionContainer">
                            {action && (
                                <>
                                    <div className={`action ${style}`}>{renderIcon}</div>
                                    <div className={style}>{action}</div>
                                </>
                            )}
                        </div>
                    </div>
                
            </div>
            {group === 'custom_questions' && (
                <div class="input__container--inline">
                    <label className="form__label" style={{ marginRight: '2.5rem' }}>Costs</label>
                    <div>
                        <input className="form__input" style={{ maxWidth: '200px' }} name={`${name}_custom_costs`} onChange={(e) => handleLocalCostChange(e)} value={customCost} />
                    </div>
                </div>
            )}
        </>
    )
    
}

export default Select;
