import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import axios from 'axios';
import Localbase from 'localbase';
import Cookie from 'js-cookie';

import { ReactComponent as Cross } from '../../assets/cross.svg';
import { ReactComponent as Check } from '../../assets/check.svg';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';

import { SET_SURVEY_COMPLETE, UPDATE_SURVEY_KEY } from '../../types/surveyTypes';

import './styles/SurveySummary.css';

export const SurveySummary = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [cost, setCost] = useState(0.00);
    const [failedCosts, setFailedCosts] = useState(0.00);
    const [advisoryCosts, setAdvisoryCosts] = useState(0.00);
    const [totalCost, setTotalCost] = useState(0.00);
    const [adjustment, setAdjustment] = useState(0);
    const [icon, setIcon] = useState('pass');
    const [loading, setLoading] = useState(false);
    const [doorsArr, setDoorsArr] = useState([]);
    const [floorPlansArray, setFloorPlansArray] = useState([]);
    const [systemCosts, setSystemCosts] = useState({});
    const [error, setError] = useState(null);
    const [costsActive, setCostsActive] = useState(false);

    const surveySelector = useSelector((state) => state.survey);
    const { id, recordId, doors, totalFields, floorPlans } = surveySelector;
    
    const floorPlanSelector = useSelector((state) => state.floorPlan);
    const { plans, pins } = floorPlanSelector;

    useEffect(() => {
        const surveyId = localStorage.getItem('activeSurvey');
        const savedCostsActive = JSON.parse(localStorage.getItem('costsActive'));

        if (savedCostsActive) {
            if (savedCostsActive[surveyId]) {
                setCostsActive(savedCostsActive[surveyId] === 'yes' ? true : false);
            }
        }
    }, []);

    const handleCompleteSurvey = async () => {
        setLoading(true);
        const summary = { inspection_total: totalCost, inspection_adjustment: adjustment, inspection_initial_cost: cost };

        const surveyId = localStorage.getItem('activeSurvey');

        let summarySaved = JSON.parse(localStorage.getItem('summary'));

        if (summarySaved) {
            summarySaved[surveyId] = summary;
            localStorage.setItem('summary', JSON.stringify(summarySaved));
        } else {
            localStorage.setItem('summary', JSON.stringify({ [surveyId]: summary }));
        }

        let survey = JSON.parse(localStorage.getItem('survey'));
        const clientData = JSON.parse(localStorage.getItem('clientData'));
        const doors = survey[surveyId]?.doors;
        const doorCosts = JSON.parse(localStorage.getItem('doorCosts'));
        const doorScores = JSON.parse(localStorage.getItem('doorScores'));
        
        let doorsArray = Object.keys(doors);
        const db = new Localbase('saved-uploads');

        let tmpRecordId = 0;
        let promises = [];
        
        const fd = new FormData();

        if (clientData) {
            for (const [key, value] of Object.entries(clientData[surveyId])) {
                if (key === 'inspection_building_photo') {
                    try {
                        const document = await db.collection('building-photos').doc(id).get();
                        
                        if (document) {
                            fd.append(key, document, document?.name);
                        }
                    } catch (error) {
                        console.log(error);
                    }
                } else if (key === 'inspection_doors') {
                    if (doors) {
                        fd.append(key, Object.keys(doors).length);
                    }
                } else {
                    fd.append(key, value);
                }
            }
        }

        try {
            const { data: surveyData } = await axios.post('/app_handler.php', fd, { headers: { 'Wg-Method': 'SAVE_CLIENTDATA', 'Wg-Key': Cookie.get('accessToken'), 'Wg-RecordId': recordId }});
            
            tmpRecordId = surveyData?.record_id;

            if (surveyData?.survey_key) {
                dispatch({ type: UPDATE_SURVEY_KEY, payload: surveyData?.survey_key });

                // survey[id]?.surveyKey = surveyData?.survey_key;
                survey[surveyId].surveyKey = surveyData?.survey_key;
                localStorage.setItem('survey', JSON.stringify(survey));
            }
        } catch (error) {
            setError('The survey wasn\'t fully uploaded. Please complete the survey when you have an Internet connection.');
            setLoading(false);
        }
        let offlineFloorPlansTmp = [];
        
        const uploadedFloorPlans = JSON.parse(localStorage.getItem('uploadedPlans'));

        if (uploadedFloorPlans) {
            if (uploadedFloorPlans[surveyId]) {
                if (uploadedFloorPlans[surveyId] !== null) {
                    uploadedFloorPlans[surveyId].forEach((plan) => offlineFloorPlansTmp.push(plan.id));
                }
            }
        }
 
        doorsArray.forEach(async (i) => {
            const fd = new FormData();

            if (doors[i]?.data) {
                for (const [key, value] of Object.entries(doors[i]?.data)) {
                    if (value.type === 'file') {
                        try {
                            const document = await db.collection(`door-images-${surveyId}`).doc(`${i}_${key}`).get();

                            if (document) {
                                if (document.file) {
                                    fd.append(key, document?.file, document?.file?.name);
                                }
                            }
                        } catch (error) {
                            console.log(error);
                        }
                    } else {
                        fd.append(key, value.value);
                    }
                }
            }

            if (doors[i]) {
                if (floorPlans) {
                    let planLabel = '';
                    const tmpPin = pins.find((pin) => pin.id === doors[i]?.pinId);
                    const tmpPlan = floorPlans.find((plan) => plan.id === doors[i]?.planRef);
    
                    if (plans) {
                        const tmpFloorPlan = Object.values(plans).find((plan) => plan.id === doors[i]?.planRef);
    
                        planLabel = tmpFloorPlan?.label;
                    }

                    if (tmpPin) {
                        fd.append('door_pin_position', `${tmpPin.position.x - 5}, ${tmpPin.position.y - 26}`);
                    }
    
                    if (tmpPlan) {
                        if (tmpPlan.image) {
                            fd.append('door_floorplan', tmpPlan.image);
                        }
                        fd.append('door_floorplan_ref', planLabel);
                        fd.append('door_floorplan_planid', tmpPlan?.id);
                    }
                }
            }
    
            if (doorCosts) {
                if (doorCosts[i]) {
                    for (const [key, value] of Object.entries(doorCosts[i])) {
                        if (doorScores) {
                            if (doorScores[i]) {
                                for (const [formKey, formValue] of Object.entries(doorScores[i])) {
                                    if (formValue !== "1.0" && key === formKey) {
                                        fd.append(`costs_${key}`, value);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            promises.push(axios.post('/app_handler.php', fd, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'WG-EntryId': survey[id]?.doors[i]?.doorId || survey[id]?.doors[i]?.id,
                    'WG-RecordId': tmpRecordId,
                    'Wg-Completed': 1,
                    'Wg-Method': 'SAVE_DOOR',
                    'Wg-Key': Cookie.get('accessToken')
                }
            }));
        });

        promises.push(axios.post('/app_handler.php', summary, { headers: { 'Wg-Method': 'SAVE_SURVEY_SUMMARY', 'Wg-Key': Cookie.get('accessToken'), 'Wg-RecordId': tmpRecordId }}));

        

        axios.all(promises)
            .then(axios.spread((...args) => {
                offlineFloorPlansTmp.forEach(async (planRef) => {
                    const fd = new FormData();
        
                    try {
                        const document = await db.collection('pdf-photos').doc(planRef).get();
                        
                        if (document) {
                            fd.append('door_floorplan', document, document?.name);
                            fd.append('door_floorplan_id', planRef);
        
                            await axios.post('/app_handler.php', fd, { headers: { 'Wg-Method': 'UPLOAD_FLOORPLAN', 'Wg-Key': Cookie.get('accessToken'), 'Wg-RecordId': recordId }});
                        }
                    } catch (error) {
                        console.log(error);
                    }
                });

                completeSurvey();
            }))
            .catch((error) => {
                setError('The survey wasn\'t fully uploaded. Please complete the survey when you have an Internet connection.');
                setLoading(false);
            });
    }

    const completeSurvey = () => {
        dispatch({ type: SET_SURVEY_COMPLETE });
        history.push('/survey/complete');
    };

    const handleBack = () => {
        history.push('/survey/doors');
    };

    const handleAdjustment = (e) => {
        setAdjustment(e.target.value);
    };

    useEffect(() => {
        const costs = JSON.parse(localStorage.getItem('costs'));

        setSystemCosts(costs);
    }, []);

    useEffect(() => {
        const doorsValues = Object.values(doors);

        setDoorsArr(doorsValues);
    }, [doors]);

    useEffect(() => {
        const surveyId = localStorage.getItem('activeSurvey');
        const survey = JSON.parse(localStorage.getItem('survey'));

        if (survey) {
            const localDoors = survey[surveyId]?.doors;
    
            if (localDoors && doorsArr.length) {
                const localDoorsArray = Object.values(localDoors);
    
                if (localDoorsArray.length !== doorsArr.length) {
                    history.push('/survey/doors?error');
                } else {
                    doorsArr.some((door) => {
                        if (door?.completedFields !== totalFields) {
                            history.push('/survey/doors?error');
    
                            return true;
                        }
                    });
                }
            }
        }
    }, [doorsArr]);

    useEffect(() => {
        if (doorsArr) {
            const costTotal = doorsArr.reduce((acc, current) => {

                if (current?.total) {
                    return acc + current?.total;
                }

                return acc;
            }, 0);

            let failedCosts = 0.00;
            let advisoryCosts = 0.00;

            const doorCostsForCustomCosts = JSON.parse(localStorage.getItem('doorCosts'));

            if (systemCosts) {
                doorsArr.map((door) => {
                    let scoresArr = [];

                    if (door?.scores) {
                        for (const [key, value] of Object.entries(door?.scores)) {
                            scoresArr.push({ key, value });
                        }
                    }

                    scoresArr.map((score) => {
                        if (score?.value === "0.0") {
                            if (systemCosts[score?.key]) {
                                failedCosts += parseFloat(systemCosts[score?.key]);
                            } else if (doorCostsForCustomCosts[id][door.id][score?.key]) {
                                failedCosts += parseFloat(doorCostsForCustomCosts[id][door.id][score?.key]);
                            }
                        } else if (score?.value === "0.5") {
                            if (systemCosts[score?.key]) {
                                advisoryCosts += parseFloat(systemCosts[score?.key]);
                            } else if (doorCostsForCustomCosts[id][door.id][score?.key]) {
                                advisoryCosts += parseFloat(doorCostsForCustomCosts[id][door.id][score?.key]);
                            }
                        }
                    });
                });
            }

            doorsArr.some((door) => {
                if (door?.scores) {
                    const doorScores = Object.values(door?.scores);
        
                    if (doorScores.findIndex(el => el === "0.0") !== -1 || doorScores.findIndex(el => el === "0.5") !== -1) {
                        setIcon('fail');
                        return;
                    }
                }
            });

            let tmpFloorPlans = [];

            if (plans) {
                Object.values(plans).forEach((plan) => {
                    tmpFloorPlans.push({ label: plan.label, doors: doorsArr.filter((door) => door.planRef === plan.id) });
                });
            } else {
                tmpFloorPlans.push({ doors: doorsArr });
            }

            const total = failedCosts + advisoryCosts;
            
            setFailedCosts(failedCosts);
            setAdvisoryCosts(advisoryCosts);
            setCost(costTotal);
            setTotalCost(total);
            setAdjustment(0);
            setFloorPlansArray(tmpFloorPlans);
        }
    }, [doorsArr, systemCosts, plans]);

    useEffect(() => {
        if (adjustment) {
            setTotalCost(parseFloat((cost + ((cost / 100) * parseInt(adjustment)))).toFixed(2));
        }
    }, [adjustment])

    return (
        <Fragment>
            <Header>Survey Summary</Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                <div className="form__buttons">
                    <button className="form__button secondary" onClick={handleBack}>Back</button>
                </div>
                <div className="surveySuccess__container">
                    {icon && icon === 'pass' ? <Check /> : <Cross /> }
                </div>
                <div className="surveyDoors__container">
                    <p style={{ fontWeight: '600' }}>{icon && icon === 'pass' ? 'Please review the cost summary below' : 'There are items that require attention. A summary can be found below.'}</p>
                    {floorPlansArray && floorPlansArray.map((plan) => {
                        return (
                            <div>
                                {plan.label && (
                                    <h4>{plan?.label}</h4>
                                )}
                                <div>
                                    {plan.doors.map((door, i) => {
                                        let doorFailedClass = '';
                                        let doorFailedMessage = '';
                                        let doorAdvisoryClass = '';
                                        let doorAdvisoryMessage = '';
                                        let doorPassClass = '';
                                        let doorPassMessage = '';
                                        let failedFields = [];
                                        let advisoryFields = [];
                
                                        if (door?.scores) {
                                            const scoreValues = Object.values(door?.scores);

                                            console.log(scoreValues);
                        
                                            if (scoreValues) {
                                                failedFields = scoreValues.filter((score) => score === '0.0');
                                                advisoryFields = scoreValues.filter((score) => score === '0.5');
                                            }
                        
                                            if (!failedFields.length && !advisoryFields.length) {
                                                doorPassClass = 'pass';
                                                doorPassMessage = 'Passed';
                                            }
                        
                                            if (failedFields.length > 0) {
                                                doorFailedClass = 'fail';
                                                doorFailedMessage = `${failedFields.length} Failed`;
                                            }
                                            
                                            if (advisoryFields.length > 0) {
                                                doorAdvisoryClass = 'advisory';
                                                doorAdvisoryMessage = `${advisoryFields.length} Advisory`;
                                            }
                                        }

                                        const surveyId = localStorage.getItem('activeSurvey'); 
                                        const survey = JSON.parse(localStorage.getItem('survey')); 
                                        let ref = '';

                                        if (survey && survey[surveyId]) {
                                            const doors = survey[surveyId]?.doors;
    
    
                                            if (doors) {
                                                if (doors[door?.id]) {
                                                    ref = doors[door?.id].reference;
                                                }
                                            }
                                        }
                
                                        return (
                                            <div key={door?.id} className="surveyDoor__container" style={{ marginTop: i === 0 ? '1.5rem' : '0' }}>
                                                <div style={{ width: '50%', fontWeight: 'bold' }}>{ref && (<span>{ref}:</span>)}</div>
                                                <div style={{ width: '50%' }}>
                                                    {doorFailedMessage && (<div className={doorFailedClass} style={{ textAlign: 'right' }}><span>{doorFailedMessage}</span></div>)}
                                                    {doorAdvisoryMessage && (<div className={doorAdvisoryClass} style={{ textAlign: 'right', marginTop: '0.25rem' }}><span>{doorAdvisoryMessage}</span></div>)}
                                                    {doorPassMessage && (<div className={doorPassClass} style={{ textAlign: 'right', marginTop: '0.25rem' }}><span>{doorPassMessage}</span></div>)}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
                {costsActive && (
                    <Fragment>
                        <div className="surveyTotal__container">
                            <div className="surveyTotal__row">
                                <div>Cost:</div>
                                <div>£{cost}</div>
                            </div>
                            <div className="surveyTotal__row">
                                <div>Advisory Cost:</div>
                                <div className="advisory-cost">£{advisoryCosts}</div>
                            </div>
                            <div className="surveyTotal__row">
                                <div>Failed Cost:</div>
                                <div className="failed-cost">£{failedCosts}</div>
                            </div>
                            <div className="surveyTotal__row">
                                <div>Adjustment</div>
                                <div><input type="number" value={adjustment} onChange={handleAdjustment} /> %</div>
                            </div>
                            <div className="surveyTotal__row total">
                                <div>Total Cost:</div>
                                <div>£{totalCost}</div>
                            </div>
                        </div>
                        <p>Please ensure you've completed all necassary changes before you proceed. After you've completed the survey you cannot go back and make further changes.</p>
                    </Fragment>
                )}
                <div className="form__buttons">
                    <button className="form__button form__button--inline" disabled={loading} onClick={handleCompleteSurvey}>Complete Survey <PulseLoader loading={loading} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
                </div>
                {error && (<div class="error" style={{ textAlign: 'center' }}>{error}</div>)}
            </Content>
            <BottomNav />
        </Fragment>
    )
}
