import React, { Fragment, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { clearSurvey } from '../../functions/utils';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';

import { CLEAR_SURVEY, CLEAR_PDF_SELECTOR } from '../../types/surveyTypes';
import { CLEAR_FLOORPLANS } from '../../types/floorPlanTypes';

import './styles/SurveyComplete.css';

export const SurveyComplete = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const surveySelector = useSelector((state) => state.survey);
    const authSelector = useSelector((state) => state.auth);
    const { surveyKey, completed } = surveySelector;
    const { user } = authSelector;

    const handleStartAgain = () => {
        dispatch({ type: CLEAR_SURVEY });
        dispatch({ type: CLEAR_FLOORPLANS });
        dispatch({ type: CLEAR_PDF_SELECTOR });

        clearSurvey();

        history.push('/survey/new');
    }

    useEffect(() => {
        return () => {
            dispatch({ type: CLEAR_SURVEY });
            dispatch({ type: CLEAR_FLOORPLANS });
            dispatch({ type: CLEAR_PDF_SELECTOR });

            clearSurvey();
        }
    }, [])

    return (
        <Fragment>
            <Header>Survey Complete</Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                {completed ? (
                    <Fragment>
                        <p style={{ marginTop: '2rem', textAlign: 'center' }}>To view a full report including a detailed schedule of works and suggested costs, please click "Download Survey (PDF)"</p>
                        <div className="form__buttons">
                            <a target="_blank" href={`https://www.webglu21.co.uk/survey/?${surveyKey}&${user?.secret_code}`} className="form__button">Download Survey (PDF)</a>
                        </div>
                        <div className="form__buttons">
                            <button className="form__button" onClick={handleStartAgain}>Click here to start a new survey</button>
                        </div>
                    </Fragment>
                ) : <Redirect to="/" />}
            </Content>
            <BottomNav />
        </Fragment>
    )
}
