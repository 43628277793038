import { LOAD_COSTS_REQUEST, LOAD_COSTS_SUCCESS, LOAD_COSTS_FAILURE, SET_COSTS_REQUEST, SET_COSTS_SUCCESS, SET_COSTS_FAILURE, GET_COSTS_REQUEST, GET_COSTS_SUCCESS, GET_COSTS_FAILURE } from '../types/costsTypes';

const costsObj = JSON.parse(localStorage.getItem('costs'));
const dependencies = JSON.parse(localStorage.getItem('dependencies'));

const initialState = {
    form: dependencies?.form_templates?.costs?.sections || [],
    costs: costsObj || {},
    loading: false,
    errors: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_COSTS_REQUEST:
            return {
                ...state,
                loading: true,
                errors: null
            }
        case LOAD_COSTS_SUCCESS:
            return {
                ...state,
                loading: false,
                form: action.payload
            }
        case LOAD_COSTS_FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.payload
            }
        case SET_COSTS_REQUEST:
            return {
                ...state,
                loading: true,
                errors: null
            }
        case SET_COSTS_SUCCESS:
            return {
                ...state,
                loading: false,
                costs: action.payload
            }
        case SET_COSTS_FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.payload.error,
                costs: action.payload.data
            }
        case GET_COSTS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_COSTS_SUCCESS:
            return {
                ...state,
                loading: false,
                costs: action.payload
            }
        case GET_COSTS_FAILURE:
            return {
                ...state,
                loading: false
            }
        default:
            return state;
    }
};