import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as Cross } from '../../assets/cross.svg';
import { ReactComponent as Tick } from '../../assets/check.svg';
import { ReactComponent as Exclamation } from '../../assets/exclamation.svg';

const Radio = ({ field: { type, name, label, id, required, class: fieldClass, group }, handleChange, options, defaultValue, errorClass, hide, doorId }) => {
    const costsSelector = useSelector((state) => state.costs);
    const { costs } = costsSelector;

    const [icon, setIcon] = useState(null);
    const [customCost, setCustomCost] = useState(0.00);
    const [activeRadio, setActiveRadio] = useState(defaultValue);

    const renderIcon = () => {
        switch (icon) {
            case 'tick':
                return <Tick />;
            case 'warning':
                return <Exclamation />;
            case 'cross':
                return <Cross />;
            default:
                return;
        }
    }

    const handleLocalChange = (e) => {
        handleChange(e);
        setActiveRadio(e.target.value);
        console.log('here');
    }

    useEffect(() => {
        const event = { target: { name, value: defaultValue }};

        const activeSurvey = localStorage.getItem('activeSurvey');
        const doorCosts = JSON.parse(localStorage.getItem('doorCosts'));

        if (doorCosts && doorCosts[activeSurvey]) {
            if (doorCosts[activeSurvey][doorId]) {
                setCustomCost(doorCosts[activeSurvey][doorId][name]);
            }
        }
    }, [defaultValue]);

    return (
        <>
            <div className={`${errorClass} ${fieldClass} ${group === 'custom_questions' ? 'input__container--inline' : ''}`} style={{ display: hide ? 'none' : 'block' }}>
                <label className="form__label" style={{ marginRight: '1.75rem' }} htmlFor={id}>{group === 'custom_questions' ? 'Outcome' : label}{required === true && (<span class="text-red">*</span>)}</label>
                <div className="radioContainer">
                    {options && options.map((option) => (
                        <div className={`form__radio${activeRadio === option?.field_option_id ? ' checked' : ''}`}>   
                            <input type="radio" id={option?.field_option_id} name={name} value={option?.field_option_id} onChange={(e) => handleLocalChange(e)} />
                            <label htmlFor={option?.field_option_id}>{option?.option_name}</label>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
    
}

export default Radio;
