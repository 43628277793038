import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { v4 as uuidv4 } from 'uuid';
import Localbase from 'localbase';

import { addDoor, removeDoor } from '../../actions/surveyActions';
import { setPin, updatePins, removePin } from '../../actions/floorPlanActions';

import { FloorPlanPopup } from '../FloorPlanPopup';
import { FloorPlanPin } from '../FloorPlanPin';

import './css/FloorPlan.css';

export const FloorPlan = ({ setToggle, toggle }) => {
    const floorPlanEl = useRef(null);
    const [selectedPin, setSelectedPin] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [toggleEdit, setToggleEdit] = useState(false);
    const [toggleArrows, setToggleArrows] = useState(null);
    const [positionY, setPositionY] = useState(0);
    const [positionX, setPositionX] = useState(0);
    const [planWidth, setPlanWidth] = useState(0);
    const [planHeight, setPlanHeight] = useState(0);
    const [floorPlan, setFloorPlan] = useState(null);

    const dispatch = useDispatch();

    const floorPlanSelector = useSelector((state) => state.floorPlan);
    const { pins } = floorPlanSelector;

    const surveySelector = useSelector((state) => state.survey);
    const { id, activePlan, doors } = surveySelector;

    const addPin = () => {
        const pinId = uuidv4();
        let tmpPositionX = Math.abs(positionX);
        let tmpPositionY = Math.abs(positionY);

        if(planWidth > floorPlanEl.current.offsetWidth) {
            if (Math.abs(positionX) >= (planWidth - floorPlanEl.current.offsetWidth)) {
                tmpPositionX = planWidth - floorPlanEl.current.offsetWidth;
                console.log(tmpPositionX);
            }
        }

        if(planHeight > floorPlanEl.current.offsetHeight) {
            if (Math.abs(positionY) >= (planHeight - floorPlanEl.current.offsetHeight)) {
                tmpPositionY = planHeight - floorPlanEl.current.offsetHeight;
                console.log(tmpPositionY);
            }
        }

        let newX = (floorPlanEl.current.offsetWidth / 2) + tmpPositionX;
        let newY = (floorPlanEl.current.offsetHeight / 2) + tmpPositionY;

        if (newX > planWidth - (floorPlanEl.current.offsetWidth / 2)) {
            newX = planWidth / 2;
        }

        if (newY > planHeight - (floorPlanEl.current.offsetHeight / 2)) {
            newY = planHeight / 2;
        }

        setDisabled(true);
        setSelectedPin(null);
        dispatch(setPin(pinId, newX, newY, activePlan));
        dispatch(addDoor(id, activePlan, '', pinId));
    }

    const handleStop = (e, data) => {
        let { id } = data.node;

        let pin = pins.find((pin) => pin.id === id);

        if (pin) {
            pin.position.x = data.x;
            pin.position.y = data.y;

            dispatch(updatePins(pin));
        }
    }

    const handlePanningStop = (e) => {
        setPositionX(e.positionX);
        setPositionY(e.positionY);
    }

    const handleImageLoad = (e) => {
        setPlanHeight(e.target.height);
        setPlanWidth(e.target.width);
    }

    const handleMoveUp = () => {
        if (selectedPin) {
            let pin = pins.find((pin) => pin.id === selectedPin);
    
            if (pin) {
                pin.position.y = pin.position.y - 1;
    
                dispatch(updatePins(pin));
            }
        }
    }

    const handleMoveDown = () => {
        if (selectedPin) {
            let pin = pins.find((pin) => pin.id === selectedPin);

            if (pin) {
                pin.position.y = pin.position.y + 1;
    
                dispatch(updatePins(pin));
            }
        }
    }

    const handleMoveRight = () => {
        if (selectedPin) {
            let pin = pins.find((pin) => pin.id === selectedPin);

            if (pin) {
                pin.position.x = pin.position.x + 1;
    
                dispatch(updatePins(pin));
            }
        }
    }

    const handleMoveLeft = () => {
        if (selectedPin) {
            let pin = pins.find((pin) => pin.id === selectedPin);
    
            if (pin) {
                pin.position.x = pin.position.x - 1;
    
                dispatch(updatePins(pin));
            }
        }
    }

    const handleToggleEdit = () => {
        if (selectedPin) {
            if (!toggleEdit) {
                setToggleArrows(false);    
            }

            setToggleEdit(!toggleEdit);
        } else {
            setToggleEdit(false);
        }
    }

    const handleRemovePin = () => {
        if (selectedPin) {
            dispatch(removePin(selectedPin));

            if (doors) {
                const selectedDoor = Object.values(doors).find((door) => door.pinId === selectedPin);

                if (selectedDoor) {
                    dispatch(removeDoor(selectedDoor?.doorId, selectedDoor?.id));
                }
            }
        }

        setSelectedPin(null);
    }

    const handleToggleArrows = () => {
        if (selectedPin) {
            if (!toggleArrows) {
                setToggleEdit(false);
            }

            setToggleArrows(!toggleArrows);
        } else {
            setToggleArrows(false);
        }
    }

    const handleChangeMode = () => {
        setDisabled(!disabled);
        setSelectedPin(null);
    }

    useEffect(() => {
        async function getPDFImage() {
            const db = new Localbase('saved-uploads');
            
            try {
                const document = await db.collection('pdf-photos').doc(activePlan).get();

                if (document) {
                    if (document?.image) {
                        setFloorPlan(document?.image);
                    } else {
                        setFloorPlan(URL.createObjectURL(document));
                    }
                }

            } catch (error) {
                console.error(error);
            }
        } 

        getPDFImage();

    }, [activePlan]);

    useEffect(() => {
        if (!selectedPin) {
            setToggleEdit(false);
        }
    }, [selectedPin]);

    return (
        <div className="container">
            <div className="floorPlan__header">
                <button type="button" onClick={() => setToggle(!toggle)}>
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
                </button>
            </div>
            <TransformWrapper
                defaultScale={1}
                defaultPositionX={0}
                defaultPositionY={1}
                onPanningStop={handlePanningStop}
                options={{
                    disabled,
                    limitToWrapper: true
                }}
            >
                {({ zoomIn, zoomOut, setTransform, scale, ...rest }) => (
                    <div className="floorPlan__container">
                        <div className="floorPlan__plan" ref={floorPlanEl} style={{ cursor: !disabled ? 'grab' : 'auto' }}>
                            <TransformComponent>
                                {/* <img className="floorPlan__image" src={plan} alt="Floor Plan" /> */}
                                <img className="floorPlan__image" src={floorPlan} alt="Floor Plan" onLoad={handleImageLoad} />
                                {pins && pins.filter((pin) => pin.planRef === activePlan).map((pin) => {
                                    return (
                                        <FloorPlanPin pin={pin} active={selectedPin} disabled={disabled} scale={scale} handleStop={handleStop} setSelectedPin={setSelectedPin} />
                                    )
                                })}
                            </TransformComponent>
                        </div>
                        <div className="floorPlan__tools">
                            <button type="button" onClick={zoomIn} disabled={disabled}>
                                <svg className={`w-6 h-6 ${disabled && 'disabled'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 8a1 1 0 011-1h1V6a1 1 0 012 0v1h1a1 1 0 110 2H9v1a1 1 0 11-2 0V9H6a1 1 0 01-1-1z" /><path fillRule="evenodd" d="M2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8zm6-4a4 4 0 100 8 4 4 0 000-8z" clipRule="evenodd" /></svg>
                            </button>
                            <button type="button" onClick={zoomOut} disabled={disabled || scale === 1}>
                                <svg className={`w-6 h-6 ${(disabled || scale === 1) && 'disabled'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" /><path fillRule="evenodd" d="M5 8a1 1 0 011-1h4a1 1 0 110 2H6a1 1 0 01-1-1z" clipRule="evenodd" /></svg>
                            </button>
                            <button type="button" onClick={handleChangeMode}>
                                <svg className={`w-6 h-6 ${!disabled && 'disabled'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" /></svg>
                            </button>
                            <button type="button" onClick={addPin} disabled={scale > 1}>
                                <svg className={`w-6 h-6 ${scale > 1 && 'disabled'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" /></svg>
                            </button>
                            <button type="button" onClick={handleToggleArrows} disabled={!selectedPin || !disabled}>
                                <svg className={`w-6 h-6 ${(!selectedPin || !disabled) && 'disabled'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 4a1 1 0 011-1h4a1 1 0 010 2H6.414l2.293 2.293a1 1 0 11-1.414 1.414L5 6.414V8a1 1 0 01-2 0V4zm9 1a1 1 0 010-2h4a1 1 0 011 1v4a1 1 0 01-2 0V6.414l-2.293 2.293a1 1 0 11-1.414-1.414L13.586 5H12zm-9 7a1 1 0 012 0v1.586l2.293-2.293a1 1 0 111.414 1.414L6.414 15H8a1 1 0 010 2H4a1 1 0 01-1-1v-4zm13-1a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 010-2h1.586l-2.293-2.293a1 1 0 111.414-1.414L15 13.586V12a1 1 0 011-1z" clipRule="evenodd" /></svg>
                            </button>
                            <button onClick={handleToggleEdit} disabled={!selectedPin || !disabled}>
                                <svg className={`w-6 h-6 ${(!selectedPin || !disabled) && 'disabled edit'}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" /></svg>
                            </button>
                            <button onClick={handleRemovePin} disabled={!selectedPin || !disabled}>
                                <svg className={`w-6 h-6 ${(!selectedPin || !disabled) && 'disabled'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" /></svg>
                            </button>
                        </div>
                    </div>
                )}
            </TransformWrapper>
            {disabled ? <div style={{ color: 'black' }}>Pin Editing Mode</div> : <div style={{ color: 'black' }}>Map Mode</div>}
            {toggleEdit && selectedPin && (
                <FloorPlanPopup id={selectedPin} setToggleEdit={setToggleEdit} />
            )}
            {toggleArrows && (
                <div className="popup popup--arrows">
                    <div className="popup__row" style={{ justifyContent: 'flex-end'}}>
                        <button onClick={() => setToggleArrows(false)}>
                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                        </button>
                    </div>
                    <div className="popup__row">
                        <button type="button" onClick={handleMoveUp} onToch>
                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                        </button>
                    </div>
                    <div className="popup__row">
                        <button type="button" onClick={handleMoveLeft}>
                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" /></svg>
                        </button>
                        <button style={{ marginLeft: '1rem' }} type="button" onClick={handleMoveRight}>
                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                        </button>
                    </div>
                    <div className="popup__row">
                        <button type="button" onClick={handleMoveDown}>
                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}