import axios from 'axios';
import Cookie from 'js-cookie';

import {
    TOGGLE_MENU,
    CLOSE_MENU,
    NAVIGATION_REQUEST,
    NAVIGATION_FAIL,
    NAVIGATION_SUCCESS,
    MENU_REQUEST,
    MENU_SUCCESS,
    MENU_FAIL,
    GET_DEPENDENCIES_REQUEST,
    GET_DEPENDENCIES_SUCCESS,
    GET_DEPENDENCIES_FAIL,
    SEND_DATA_REQUEST,
    SEND_DATA_SUCCESS,
    SEND_DATA_FAILURE,
} from '../types/appTypes';

import { UPDATE_TOTAL_FIELDS } from '../types/surveyTypes';

export const toggleMenu = () => dispatch => {
    dispatch({ type: TOGGLE_MENU });
}

export const closeMenu = () => dispatch => {
    dispatch({ type: CLOSE_MENU });
}

export const setNavigation = () => async dispatch => {
    try {
        dispatch({ type: NAVIGATION_REQUEST });

        const navigation = await axios.get('/app_handler.php', { headers: {} });

        if (navigation) {
            dispatch({ type: NAVIGATION_SUCCESS, payload: navigation });
        }

    } catch (error) {
        dispatch({ type: NAVIGATION_FAIL });
    }
}

export const getMenu = () => async dispatch => {
    try {
        dispatch({ type: MENU_REQUEST });

        const { data } = await axios.get('/app_handler.php', { headers: { 'Wg-method': 'MENU', 'Wg-Key': Cookie.get('accessToken') } });

        if (data) {
            dispatch({ type: MENU_SUCCESS, payload: data });
        }

    } catch (error) {
        dispatch({ type: MENU_FAIL });
    }
};

export const getDependencies = () => async dispatch => {
    try {
        dispatch({ type: GET_DEPENDENCIES_REQUEST });

        const { data } = await axios.get('/app_handler.php', { headers: { 'WG-Method': 'DEPENDENCIES', 'WG-Key': Cookie.get('accessToken') }});

        dispatch({ type: GET_DEPENDENCIES_SUCCESS, payload: data?.dependencies });
        dispatch({ type: UPDATE_TOTAL_FIELDS, payload: data?.dependencies?.field_count });

        localStorage.setItem('dependencies', JSON.stringify(data?.dependencies));
    } catch (error) {
        dispatch({ type: GET_DEPENDENCIES_FAIL, payload: error?.response?.data.errors });
    }
};

export const sendHelpData = () => async dispatch => {
    const survey = localStorage.getItem('survey');
    const activeSurvey = localStorage.getItem('activeSurvey');
    const clientData = localStorage.getItem('clientData');
    const doorCosts = localStorage.getItem('doorCosts');
    const doorScores = localStorage.getItem('doorScores');
    const summary = localStorage.getItem('summary');
    const floorPlan = localStorage.getItem('floorPlan');
    const floorPlans = localStorage.getItem('floorPlans');
    const activePlan = localStorage.getItem('activePlan');
    const uploadedPlans = localStorage.getItem('uploadedPlans');
    const costsActive = localStorage.getItem('costsActive');
    const dependencies = localStorage.getItem('dependencies');
    const costs = localStorage.getItem('costs');
    const recentSurveys = localStorage.getItem('recentSurveys');

    try {
        dispatch({ type: SEND_DATA_REQUEST });

        const { data } = await axios.post('/app_handler.php', { survey, active_survey: activeSurvey, client_data: clientData, door_costs: doorCosts, door_scores: doorScores, summary, floor_plan: floorPlan, floor_plans: floorPlans, active_plan: activePlan, uploaded_plans: uploadedPlans, costs_active: costsActive, dependencies, costs, recent_surveys: recentSurveys }, { headers: { 'WG-Method': 'HELP_DATA', 'WG-Key': Cookie.get('accessToken') }});
    
        dispatch({ type: SEND_DATA_SUCCESS });
        
    } catch (error) {
        dispatch({ type: SEND_DATA_FAILURE });
    }

};