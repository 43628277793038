import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setPinRef } from '../../actions/floorPlanActions';
import { saveDoorRef } from '../../actions/surveyActions';

import './css/FloorPlanPopup.css';

export const FloorPlanPopup = ({ id, setToggleEdit }) => {
    const dispatch = useDispatch();

    const [ref, setRef] = useState(true);
    const [door, setDoor] = useState(null);
    
    const surveySelector = useSelector((state) => state.survey);
    const { doors } = surveySelector;
    const floorPlanSelector = useSelector((state) => state.floorPlan);
    const { pins } = floorPlanSelector;

    const handleSave = () => {
        if (id) {
            dispatch(setPinRef(id, ref));
        }

        if (door) {
            dispatch(saveDoorRef(door.id, ref));
        }

        setToggleEdit(false);
    }

    const handleRefChange = (e) => {
        setRef(e.target.value);
    }

    useEffect(() => {
        const pin = pins.find((pin) => pin.id === id);

        if (pin) {
            setRef(pin.reference);
        }
    }, [id]);

    useEffect(() => {
        const selectedDoor = Object.values(doors).find((door) => door.pinId === id);

        if (selectedDoor) {
            setDoor(selectedDoor);
        }
    }, [doors, id]);

    return (
        <div className="popup popup--edit">
            <div className="popup__container">
                <span className="title">Edit Pin</span>
                <input type="text" value={ref} onChange={handleRefChange} />
            </div>
            <div className="popup__content">
                <button type="button" onClick={() => setToggleEdit(false)}>Close</button>
                <button className="save" type="button" onClick={handleSave}>Continue</button>
            </div>
        </div>
    )
}
