import { LOAD_RULES_REQUEST, LOAD_RULES_SUCCESS, LOAD_RULES_FAILURE } from '../types/rulesTypes';

const rules = JSON.parse(localStorage.getItem('fieldRules'));

const initialState = {
    loading: false,
    error: null,
    rules,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_RULES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case LOAD_RULES_SUCCESS:
            return {
                ...state,
                loading: false,
                rules: action.payload,
            }
        case LOAD_RULES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state;
    }
};