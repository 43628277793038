import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import PulseLoader from 'react-spinners/PulseLoader';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';

import Input from '../../components/forms/Input';
import File from '../../components/forms/File';
import Textarea from '../../components/forms/Textarea';
import Select from '../../components/forms/Select';

import { loadDoor, saveDoor, clearDoorProceed } from '../../actions/surveyActions';
import { setPinRef } from '../../actions/floorPlanActions';
import Radio from '../forms/Radio';

export const Door = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState(null);
    const [previews, setPreviews] = useState({});
    const [costs, setCosts] = useState(null);
    const [scores, setScores] = useState(null);
    const [missingFields, setMissingFields] = useState(false);
    const [hideFields, setHideFields] = useState([]);
    const [hideOptions, setHideOptions] = useState([]);
    const [defaultHideOptions, setDefaultHideOptions] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();

    const surveySelector = useSelector((state) => state.survey);
    const { recordId, doors, doorLoading, doorFormLoading, doorFormProceed, doorForm, activePlan, requiredFields } = surveySelector;

    const floorPlanSelector = useSelector((state) => state.floorPlan);
    const { pins, plans } = floorPlanSelector;
    
    const rulesSelector = useSelector((state) => state.rules);
    const { rules } = rulesSelector;

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (value) {
            //get rule based off field_name
            const fieldRules = rules.filter((rule) => rule.current_field_name === name);
    
            fieldRules.map((rule) => {
                if (rule.foreign_value === value) {
                    if (rule.rule_condition === 'hide') {
                        setHideFields(prev => [...prev, rule.change_field_name]);
                    }
                } else {
                    setHideFields(prev => prev.filter((changeFieldName) => changeFieldName === rule.change_field_name));
                }
            });
    
            if (formData) {
                setFormData({ ...formData, [name]: { ...formData[name], value }});
                
                if (hideOptions.includes(name)) {
                    let tmpFormData = formData;

                    console.log(hideOptions);
                    console.log(name);
                    console.log(value);

                    hideOptions.forEach((fieldName) => {
                        if (fieldName !== name) {
                            tmpFormData[fieldName].value = "";
                        }
                    })

                    setFormData({ ...tmpFormData, [name]: { ...formData[name], value }});
                }

                console.log(formData);
            }
        }
    }

    useEffect(() => {
        let tmpCosts = costs;
        if (tmpCosts) {
            hideFields.map((f) => delete tmpCosts[f]);
        }

        let tmpScores = scores
        if (tmpScores) {
            hideFields.map((f) => delete tmpScores[f]);
        }

        setCosts(tmpCosts);
        setScores(tmpScores);
    }, [hideFields]);

    useEffect(() => {
        console.log(hideOptions);
    }, [hideOptions]);

    const handleCosts = (name, cost) => {
        setCosts({ ...costs, [name]: cost });
    }

    const fileSelectedHandler = (event) => {
        const { name, files } = event.target;
        if (formData) {
            setFormData({ ...formData, [name]: { ...formData[name], value: files[0] }});
        }
        setPreviews({ ...previews, [name]: URL.createObjectURL(files[0]) });
    }

    const handleScore = (name, score) => {
        setScores({ ...scores, [name]: score });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let planLabel = '';
        
        if (plans) {
            const tmpPlan = Object.values(plans).find((plan) => plan.id === activePlan);

            if (tmpPlan) {
                planLabel = tmpPlan.label;
            }
        }

        // const tmpFormData = JSON.parse(localStorage.getItem('tmpFormData'));
        
        dispatch(saveDoor(formData, id, recordId, costs, scores, activePlan, planLabel, requiredFields));

        if (doors[id].pinId) {
            dispatch(setPinRef(doors[id].pinId, formData['door-reference_5f7c9de1088a0_formid_436']['value']));
        }
    }

    useEffect(() => {
        if (doorForm) {
            let initialData = {};

            doorForm.sections && doorForm.sections.map((section) => {
                section.fields.map((field) => {
                    let value = doors?.[id]?.data?.[field?.name]?.value || '';

                    if (field?.name === 'door-reference_5f7c9de1088a0_formid_436') {
                        value = doors[id]?.reference || value;
                    }

                    initialData = {...initialData, [field?.name]: { type: field?.type || field?.component, value: typeof value === 'object' ? '' : value }};
                });
            });

            setFormData(initialData);

            const surveyId = localStorage.getItem('activeSurvey');

            let doorCosts = {};
            let doorScores = {};

            if (surveyId) {
                const savedDoorCosts = JSON.parse(localStorage.getItem('doorCosts'));

                if (savedDoorCosts) {
                    if (savedDoorCosts[surveyId]) {
                        if (savedDoorCosts[surveyId][id]) {
                            doorCosts = savedDoorCosts[surveyId][id];
                        }
                    }
                }
                
                const savedDoorScores = JSON.parse(localStorage.getItem('doorScores'));
                
                if (savedDoorScores) {
                    if (savedDoorScores[surveyId]) {
                        if (savedDoorScores[surveyId][id]) {
                            doorScores = savedDoorScores[surveyId][id];
                        }
                    }
                }
            }

            let costsTmp = {};
        
            if (!_.isEmpty(doorCosts)) {
                for (const [key, value] of Object.entries(doorCosts)) {
                    costsTmp[key] = value;
                }
            }
    
            setCosts(costsTmp);
            
            let scoresTmp = {};
        
            if (!_.isEmpty(doorScores)) {
                for (const [key, value] of Object.entries(doorScores)) {
                    if (_.includes(Object.keys(costsTmp), key)) {
                        scoresTmp[key] = value;
                    }
                }
            }
    
            setScores(scoresTmp);
        }
    }, [doorForm]);

    useEffect(() => {
        dispatch(loadDoor(id));

        return (() => {
            setFormData(null);
            setCosts(null);
            setScores(null);
        });
    }, []);

    useEffect(() => {
        if (doorFormProceed) {
            history.push('/survey/doors');
            dispatch(clearDoorProceed());
        }
    }, [doorFormProceed]);

    return (
        <Fragment>
            <Header />
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                <div>
                    <div className="form__buttons" style={{ justifyContent: 'flex-start' }}>
                        <button className="form__button" disabled={doorLoading} onClick={() => history.push('/survey/doors')}>Back</button>
                    </div>
                    {!doorFormLoading && missingFields && (
                        <div style={{ marginTop: '1rem', textAlign: 'center' }} className="fail">You have incomplete fields. Please correct the highlighted fields to continue.</div>
                    )}
                    {doorFormLoading ? (
                        <div>Loading...</div>
                    ) : (
                        <form className="form" onSubmit={handleSubmit} encType="multipart/form-data">
                            <div className="form__instructions">{doorForm?.before_text}</div>
                            {doorForm?.sections && doorForm?.sections.map((section) => {
                                return (
                                    <Fragment>
                                        {section?.label && (<div className="form__title">{section?.label}</div>)}
                                        <div>
                                            {section?.fields && section.fields.map((field) => {
                                                let renderedField;
                                            
                                                if (field?.component === 'image' || field?.component === 'image_multiple') {
                                                    let value = '';
                                                    
                                                    if (doors[id]) {
                                                        value = doors[id]?.data?.[field?.name]?.value || '';
                                                    }

                                                    renderedField = <File field={field} existingFile={value} fileSelectedHandler={fileSelectedHandler} previews={previews} />
                                                } else if (field?.component === 'textarea') {
                                                    renderedField = <Textarea field={field} handleChange={handleChange} />
                                                } else if (field?.component === 'select' || field?.component === 'radio') {
                                                    let value = '';
                                                    let errorClass = '';

                                                    if (doors?.[id]?.data) {
                                                        value = doors?.[id]?.data?.[field?.name]?.value || '';

                                                        errorClass = value === "" && _.includes(requiredFields, field?.name) ? 'field-incompleted show' : '';

                                                        if (!doors?.[id]?.data?.[field?.name]?.value && doors?.[id]?.data?.[field?.required] && !missingFields) {
                                                            setMissingFields(true);
                                                        }
                                                    }

                                                    let selectFieldData = null;
                                                    let textBeforeField = '';

                                                    if (field?.field_data) {
                                                        selectFieldData = JSON.parse(field?.field_data) || null;
                                                        if (selectFieldData) {
                                                            if (selectFieldData?.hide_options) {
                                                                if (defaultHideOptions.indexOf(field?.name) === -1) {
                                                                    setHideOptions(prev => [...prev, field?.name]);
                                                                    setDefaultHideOptions(prev => [...prev, field?.name]);
                                                                }
                                                            }

                                                            if(selectFieldData?.text_before_field) {
                                                                textBeforeField = selectFieldData?.text_before_field;
                                                            }
                                                        }
                                                    }

                                                    if (field?.component === 'radio') {
                                                        renderedField = <Radio field={field} defaultValue={value} handleChange={handleChange} options={field?.options} handleDefaultCosts={handleCosts} handleScore={handleScore} hide={hideFields.includes(field.name)} section={section?.label} doorId={id}  />
                                                    } else {
                                                        renderedField = <Select errorClass={errorClass} field={field} defaultValue={value} handleChange={handleChange} handleDefaultCosts={handleCosts} handleScore={handleScore} options={field?.options} hide={hideFields.includes(field.name)} section={section?.label} doorId={id} fieldData={selectFieldData} defaultHideOptions={defaultHideOptions} hideOptions={hideOptions} setHideOptions={setHideOptions} textBefore={textBeforeField} />
                                                    }

                                                } else {
                                                    let value = '';
                                                    
                                                    if (doors[id]) {
                                                        value = doors[id]?.data?.[field?.name]?.value || '';
                                                    }

                                                    if (field?.name === 'door-reference_5f7c9de1088a0_formid_436') {
                                                        value = doors[id]?.reference || value;
                                                    }

                                                    renderedField = <Input field={field} value={value} handleChange={handleChange} hide={_.includes(hideFields, field.name)} section={section?.label} />
                                                }
                                            
                                                return renderedField;
                                            })}
                                        </div>
                                    </Fragment>
                                )
                            })}
                            <div className="form__buttons">
                                <button aria-label="Submit" disabled={doorLoading} className="form__button form__button--inline" type="submit">Submit <PulseLoader loading={doorLoading} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
                            </div>
                        </form>
                    )}
                </div>
            </Content>
            <BottomNav />
        </Fragment>
    )
}
