import axios from 'axios';
import Localbase from 'localbase';
import _ from 'lodash';

export const handleForm = async (url, id, data) => {
    const fd = new FormData();

    for (const [key, value] of Object.entries(data)) {
        if (value.type === 'file') {
            if (typeof value.value === 'object') {
                // console.log('value: ' + value.value);
                // console.log('name: ' + value.value.name);
                fd.append(key, value.value, value.value.name);
            } else {
                fd.append(key, value.value);
            }
        } else {
            fd.append(key, value.value);
        }
    }

    const response = await axios.post(url, fd, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Record-Id': id,
            'Wg-Method': 'SAVE_DOOR'
        }
    });

    return response;
}

export const clearSurvey = async () => {
    const surveyId = localStorage.getItem('activeSurvey');

    if (surveyId) {
        let survey = JSON.parse(localStorage.getItem('survey'));

        if (survey) {
            if (survey[surveyId]) {
                delete survey[surveyId];
            }
            
            if (_.isEmpty(survey)) {
                localStorage.removeItem('survey');
            } else {
                localStorage.setItem('survey', JSON.stringify(survey));
            }
        }

        let clientData = JSON.parse(localStorage.getItem('clientData'));

        if (clientData) {
            if (clientData[surveyId]) {
                delete clientData[surveyId];
            }

            if (_.isEmpty(clientData)) {
                localStorage.removeItem('clientData');
            } else {
                localStorage.setItem('clientData', JSON.stringify(clientData));
            }
        }

        let doorCosts = JSON.parse(localStorage.getItem('doorCosts'));

        if (doorCosts) {
            if (doorCosts[surveyId]) {
                delete doorCosts[surveyId];
            }

            if (_.isEmpty(doorCosts)) {
                localStorage.removeItem('doorCosts');
            } else {
                localStorage.setItem('doorCosts', JSON.stringify(doorCosts));
            }
        }

        let doorScores = JSON.parse(localStorage.getItem('doorScores'));

        if (doorScores) {
            if (doorScores[surveyId]) {
                delete doorScores[surveyId];
            }

            if (_.isEmpty(doorScores)) {
                localStorage.removeItem('doorScores');
            } else {
                localStorage.setItem('doorScores', JSON.stringify(doorScores));
            }
        }

        let summary = JSON.parse(localStorage.getItem('summary'));

        if (summary) {
            if (summary[surveyId]) {
                delete summary[surveyId];
            }

            if (_.isEmpty(summary)) {
                localStorage.removeItem('summary');
            } else {
                localStorage.setItem('summary', JSON.stringify(summary));
            }
        }

        let floorPlan = JSON.parse(localStorage.getItem('floorPlan'));

        console.log(floorPlan);

        if (floorPlan) {
            if (floorPlan[surveyId]) {
                delete floorPlan[surveyId];
            }

            if (_.isEmpty(floorPlan)) {
                localStorage.removeItem('floorPlan');
            } else {
                localStorage.setItem('floorPlan', JSON.stringify(floorPlan));
            }
        }

        let floorPlans = JSON.parse(localStorage.getItem('floorPlans'));

        if (floorPlans) {
            if (floorPlans[surveyId]) {
                delete floorPlans[surveyId];
            }

            if (_.isEmpty(floorPlans)) {
                localStorage.removeItem('floorPlans');
            } else {
                localStorage.setItem('floorPlans', JSON.stringify(floorPlans));
            }
        }

        let uploadedPlans = JSON.parse(localStorage.getItem('uploadedPlans'));

        if (uploadedPlans) {
            if (uploadedPlans[surveyId] || uploadedPlans[surveyId] === null) {
                delete uploadedPlans[surveyId];
            }

            if (_.isEmpty(uploadedPlans)) {
                localStorage.removeItem('uploadedPlans');
            } else {
                localStorage.setItem('uploadedPlans', JSON.stringify(uploadedPlans));
            }
        }

        let costsActive = JSON.parse(localStorage.getItem('costsActive'));

        if (costsActive) {
            if (costsActive[surveyId]) {
                delete costsActive[surveyId];
            }

            if (_.isEmpty(costsActive)) {
                localStorage.removeItem('costsActive');
            } else {
                localStorage.setItem('costsActive', JSON.stringify(costsActive));
            }
        }

        const db = new Localbase('saved-uploads');
        const activePlans = JSON.parse(localStorage.getItem('activePlan'));

        console.log(activePlans);
        
        if (activePlans) {
            if (activePlans[surveyId]) {
                console.log(activePlans[surveyId]);
                try {
                    const document = await db.collection('pdf-photos').doc(activePlans[surveyId]).get();
            
                    if (document) {
                        await db.collection('pdf-photos').doc(activePlans[surveyId]).delete();
                    }
            
                } catch (error) {
                    console.error(error);
                }
            }
        }

        try {
            await db.collection(`door-images-${surveyId}`).delete();
        } catch (error) {
            console.error(error);
        }

        try {
            const document = await db.collection('building-photos').doc(surveyId).get();
    
            if (document) {
                await db.collection('building-photos').doc(surveyId).delete();
            }
    
        } catch (error) {
            console.error(error);
        }
    }
    
    let activePlan = JSON.parse(localStorage.getItem('activePlan'));

    if (activePlan) {
        if (activePlan[surveyId]) {
            delete activePlan[surveyId];
        }

        if (_.isEmpty(activePlan)) {
            localStorage.removeItem('activePlan');
        } else {
            localStorage.setItem('activePlan', JSON.stringify(activePlan));
        }
    }

    localStorage.removeItem('activeSurvey');
}

export const clearAllSurveys = async () => {
    localStorage.removeItem('survey');
    localStorage.removeItem('clientData');
    localStorage.removeItem('doorCosts');
    localStorage.removeItem('doorScores');
    localStorage.removeItem('summary');
    localStorage.removeItem('floorPlan');
    localStorage.removeItem('floorPlans');
    localStorage.removeItem('activePlan');
    localStorage.removeItem('uploadedPlans');
    localStorage.removeItem('costsActive');

    localStorage.removeItem('activeSurvey');

    const db = new Localbase('saved-uploads');

    try {
        await db.delete();
    } catch (error) {
        
    }
}
