import axios from 'axios';
import Cookie from 'js-cookie';

import { LOAD_RULES_FAILURE, LOAD_RULES_REQUEST, LOAD_RULES_SUCCESS } from '../types/rulesTypes';

export const getRules = () => async dispatch => {
    try {
        dispatch({ type: LOAD_RULES_REQUEST });

        const { data } = await axios.get('/app_handler.php', {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Wg-Method': 'GET_RULES',
                'Wg-Key': Cookie.get('accessToken')
            }
        });

        localStorage.setItem('fieldRules', JSON.stringify(data?.rules));

        dispatch({ type: LOAD_RULES_SUCCESS, payload: data?.rules })
    } catch (error) {
        dispatch({ type: LOAD_RULES_FAILURE, payload: error })
    }
}
