import _ from 'lodash';

import { LOAD_SURVEY_REQUEST, LOAD_SURVEY_SUCCESS, LOAD_SURVEY_FAILURE, CREATE_SURVEY_REQUEST, CREATE_SURVEY_SUCCESS, CREATE_SURVEY_FAILURE, SAVE_DOOR_REQUEST, SAVE_DOOR_SUCCESS, SAVE_DOOR_FAILURE, LOAD_DOOR_REQUEST, LOAD_DOOR_SUCCESS, LOAD_DOOR_FAILURE, ADD_DOOR_REQUEST, ADD_DOOR_SUCCESS, ADD_DOOR_FAILURE, REMOVE_DOOR_REQUEST, REMOVE_DOOR_SUCCESS, REMOVE_DOOR_FAILURE, UPDATE_SURVEY_REQUEST, UPDATE_SURVEY_SUCCESS, UPDATE_SURVEY_FAILURE, CLEAR_DOOR_PROCEED, SET_SURVEY_COMPLETE, CLEAR_SURVEY, UPDATE_SURVEY_KEY, CLEAR_DOORLIST_PROCEED, UPDATE_TOTAL_FIELDS, ASSIGN_PINS_REQUEST, ASSIGN_PINS_SUCCESS, ASSIGN_PINS_FAILURE, SET_ACTIVE_PLAN_REQUEST, SET_ACTIVE_PLAN_SUCCESS, SET_ACTIVE_PLAN_FAILURE, SAVE_DOOR_REF_REQUEST, SAVE_DOOR_REF_SUCCESS, SAVE_DOOR_REF_FAILURE, CLEAR_PDF_SELECTOR, REMOVE_ALL_DOORS_REQUEST, REMOVE_ALL_DOORS_SUCCESS, REMOVE_ALL_DOORS_FAILURE, SET_PDF_SELECTOR_TRUE } from "../types/surveyTypes";

const surveyId = localStorage.getItem('activeSurvey');
const dependencies = JSON.parse(localStorage.getItem('dependencies'));
let survey = null;
let doors = null;
let activePlan = null;
let uploadedPlans = null;

if (surveyId) {
    survey = JSON.parse(localStorage.getItem('survey'));

    if (survey) {
        if (survey[surveyId]) {
            survey = survey[surveyId];
            doors = survey?.doors;
        } else {
            survey = null;
        }
    } else {
        survey = null;
    }
    
    activePlan = JSON.parse(localStorage.getItem('activePlan'));
    if (activePlan) {
        if (activePlan[surveyId]) {
            activePlan = activePlan[surveyId];
        } else {
            activePlan = null;
        }
    } else 
    {
        activePlan = null;
    }

    uploadedPlans = JSON.parse(localStorage.getItem('uploadedPlans'));
    if (uploadedPlans) {
        if (uploadedPlans[surveyId]) {
            uploadedPlans = uploadedPlans[surveyId];
        } else {
            uploadedPlans = null;
        }
    } else {
        uploadedPlans = null;
    }
}

let totalFields = [];

const tmpKeyList = dependencies?.form_templates?.door?.sections.filter((section) => {
    const { fields } = section;

    fields.map((field) => {
        if (field?.required) {
            totalFields.push(field?.name);
        }
    });
});

const initialState = {
    id: survey?.id || '',
    userId: survey?.userId || 0,
    recordId: survey?.recordId || 0,
    surveyKey: survey?.surveyKey || '',
    offline: true,
    completed: false,
    doors: doors || {},
    totalFields: totalFields.length || 0,
    floorPlans: uploadedPlans || null,
    activePlan: activePlan || null,
    activeNoPlan: survey?.activeNoPlan || false,
    redirect: null,
    doorForm: null,
    doorFormProceed: false,
    doorListProceed: false,
    proceedToPDFSelector: false,
    surveyLoading: false,
    surveyErrors: null,
    doorFormLoading: false,
    doorFormErrors: null,
    doorLoading: false,
    doorErrors: null,
    doorListLoading: false,
    doorListErrors: null,
    requiredFields: totalFields,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_SURVEY_REQUEST:
            return {
                ...state,
                id: '',
                userId: 0,
                recordId: 0,
                surveyKey: '',
                offline: true,
                completed: false,
                doors: {},
                floorPlans: null,
                activePlan: null,
                activeNoPlan: false,
                redirect: null,
                doorForm: null,
                doorFormProceed: false,
                doorListProceed: false,
                proceedToPDFSelector: false,
                surveyLoading: true,
                surveyErrors: null,
                doorFormLoading: false,
                doorFormErrors: null,
                doorLoading: false,
                doorErrors: null,
                doorListLoading: false,
                doorListErrors: null,
            }
        case LOAD_SURVEY_SUCCESS:
            return {
                ...state,
                surveyLoading: false,
                ...action.payload,
            }
        case CREATE_SURVEY_REQUEST:
            return {
                ...state,
                id: '',
                userId: 0,
                recordId: 0,
                surveyKey: '',
                offline: true,
                completed: false,
                doors: {},
                floorPlans: null,
                activePlan: null,
                activeNoPlan: false,
                redirect: null,
                doorForm: null,
                doorFormProceed: false,
                doorListProceed: false,
                proceedToPDFSelector: false,
                surveyLoading: true,
                surveyErrors: null,
                doorFormLoading: false,
                doorFormErrors: null,
                doorLoading: false,
                doorErrors: null,
                doorListLoading: false,
                doorListErrors: null,
            }
        case CREATE_SURVEY_SUCCESS:
            return {
                ...state,
                surveyLoading: false,
                id: action.payload.id,
                userId: action.payload.userId,
                recordId: action.payload.recordId,
                surveyKey: action.payload.surveyKey,
                floorPlans: action.payload.floorPlans,
                activePlan: action.payload.floorPlans === null ? null : action.payload?.floorPlans.length > 1 ? null : action.payload?.floorPlans[0].id,
                activeNoPlan: action.payload.floorPlans === null ? true : false,
                doorListProceed: !action.payload.proceedToPDFSelector,
                proceedToPDFSelector: action.payload.proceedToPDFSelector,
            }
        case CREATE_SURVEY_FAILURE:
            return {
                ...state,
                id: action.payload.id,
                userId: action.payload.userId,
                floorPlans: action.payload.floorPlans,
                doors: action.payload.doors,
                activePlan: action.payload.floorPlans === null ? null : action.payload?.floorPlans.length > 1 ? null : action.payload?.floorPlans[0].id,
                activeNoPlan: action.payload.activeNoPlan,
                surveyLoading: false,
                offline: true,
                surveyErrors: action.payload.errors,
                doorListProceed: true,
            }
        case UPDATE_SURVEY_REQUEST:
            return {
                ...state,
                surveyLoading: true,
                surveyErrors: null
            }
        case UPDATE_SURVEY_SUCCESS:
            console.log(action.payload);
            return {
                ...state,
                recordId: action.payload?.recordId,
                surveyKey: action.payload?.surveyKey,
                floorPlans: action.payload.floorPlans === null ? state.floorPlans : action.payload.floorPlans,
                activePlan: action.payload?.floorPlans === null ? state.activePlan : action.payload?.floorPlans.length > 1 ? null : action.payload?.floorPlans[0].id,
                activeNoPlan: action.payload.floorPlans === null ? state.activeNoPlan : false,
                doors: action.payload.floorPlans === null ? state.doors : {},
                doorListProceed: !action.payload.proceedToPDFSelector,
                proceedToPDFSelector: action.payload.proceedToPDFSelector,
                surveyLoading: false,
                surveyErrors: null
            }
        case UPDATE_SURVEY_FAILURE:
            return {
                ...state,
                floorPlans: action.payload?.floorPlans || state.floorPlans,
                doors: action.payload?.doors || state.doors,
                activePlan: action.payload?.activePlan || state.activePlan,
                activeNoPlan: action.payload?.activeNoPlan || state.activeNoPlan,
                surveyLoading: false,
                offline: true,
                doorListProceed: true,
            }
        case SAVE_DOOR_REQUEST:
            return {
                ...state,
                doorLoading: true,
                doorErrors: null
            }
        case SAVE_DOOR_SUCCESS:
        case SAVE_DOOR_FAILURE:

            let { id } = action.payload.door;

            return {
                ...state,
                doorLoading: false,
                doorFormProceed: true,
                recordId: action.payload.recordId,
                doors: { ...state.doors, [id]: { ...state.doors[id], ...action.payload.door } },
            }
        case LOAD_DOOR_REQUEST:
            return {
                ...state,
                doorForm: null,
                doorFormLoading: true,
                doorFormErrors: null
            }
        case LOAD_DOOR_SUCCESS:
            return {
                ...state,
                doorFormLoading: false,
                doorForm: action.payload
            }
        case LOAD_DOOR_FAILURE:
            return {
                ...state,
                doorFormLoading: false,
                doorForm: action.payload
            }
        case ADD_DOOR_REQUEST:
            return {
                ...state,
                redirect: null,
                doorListLoading: true,
                doorListErrors: null
            }
        case ADD_DOOR_SUCCESS:
        case ADD_DOOR_FAILURE:
            return {
                ...state,
                doorListLoading: false,
                doors: { ...state.doors, [action.payload.id]: action.payload },
                redirect: action.payload.id,
            }
        case REMOVE_DOOR_REQUEST:
            return {
                ...state,
                doorListLoading: true
            }
        case REMOVE_DOOR_SUCCESS:
            return {
                ...state,
                doorListLoading: false,
                doors: _.omit(state.doors, action.payload)
            }
        case REMOVE_DOOR_FAILURE:
            return {
                ...state,
                doorListLoading: false
            }
        case REMOVE_ALL_DOORS_REQUEST:
            return {
                ...state,
                doorListLoading: true
            }
        case REMOVE_ALL_DOORS_SUCCESS:
            return {
                ...state,
                doorListLoading: false,
                doors: {},
            }
        case REMOVE_ALL_DOORS_FAILURE:
            return {
                ...state,
                doorListLoading: false
            }
        case CLEAR_DOOR_PROCEED:
            return {
                ...state,
                doorFormProceed: false
            }
        case SET_SURVEY_COMPLETE:
            return {
                ...state,
                completed: true
            }
        case CLEAR_SURVEY:
            return {
                ...state,
                id: 0,
                recordId: 0,
                surveyKey: '',
                offline: true,
                completed: false,
                doors: {},
                doorForm: null,
                doorFormProceed: false,
                surveyLoading: false,
                surveyErrors: null,
                doorFormLoading: false,
                doorFormErrors: null,
                doorLoading: false,
                doorErrors: null,
                doorListLoading: false,
                doorListErrors: null,
                proceedToPDFSelector: false,
            }
        case UPDATE_SURVEY_KEY:
            return {
                ...state,
                surveyKey: action.payload
            }
        case CLEAR_DOORLIST_PROCEED:
            return {
                ...state,
                doorListProceed: false
            }
        case UPDATE_TOTAL_FIELDS:
            return {
                ...state,
                totalFields: action.payload
            }
        case ASSIGN_PINS_REQUEST:
            return {
                ...state,
                doorListLoading: true,
            }
        case ASSIGN_PINS_SUCCESS:
            let updatedDoors = state.doors;
            const pins = action.payload;

            let index = 0;

            for (const [key, value] of Object.entries(updatedDoors)) {
                value.reference = pins[index]?.reference;
                value.pinId = pins[index]?.id;
                index++;
            }

            return {
                ...state,
                doorListLoading: false,
                doors: updatedDoors,
            }
        case SET_ACTIVE_PLAN_REQUEST:
            return {
                ...state,
                doorListLoading: true,
            }
        case SET_ACTIVE_PLAN_SUCCESS:
            return {
                ...state,
                doorListLoading: false,
                activePlan: action.payload,
            }
        case SET_ACTIVE_PLAN_FAILURE:
            return {
                ...state,
                doorListLoading: false,
            }
        case SAVE_DOOR_REF_REQUEST:
            return {
                ...state,
            }
        case SAVE_DOOR_REF_SUCCESS:
            return {
                ...state,
                doors: { ...state.doors, [action.payload.id]: { ...state.doors[action.payload.id], reference: action.payload.ref }}
            }
        case SAVE_DOOR_REF_FAILURE:
            return {
                ...state,
            }
        case CLEAR_PDF_SELECTOR:
            return {
                ...state,
                proceedToPDFSelector: false
            }
        case SET_PDF_SELECTOR_TRUE:
            return {
                ...state,
                proceedToPDFSelector: true,
                activePlan: null,
            }
        default:
            return state;
    }
};