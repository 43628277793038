import { v4 as uuidv4 } from 'uuid';
import Cookie from 'js-cookie';
import axios from 'axios';
import Localbase from 'localbase';

import { LOAD_FLOORPLAN_REQUEST, LOAD_FLOORPLAN_SUCCESS, LOAD_FLOORPLAN_FAILURE, SET_PIN_REQUEST, SET_PIN_SUCCESS, SET_PIN_FAILURE, UPDATE_PIN_REQUEST, UPDATE_PIN_SUCCESS, UPDATE_PIN_FAILURE, REMOVE_PIN_REQUEST, REMOVE_PIN_SUCCESS, REMOVE_PIN_FAILURE, SET_PIN_REF_REQUEST, SET_PIN_REF_SUCCESS, SET_PIN_REF_FAILURE, GENERATE_PINS_REQUEST, GENERATE_PINS_SUCCESS, GENERATE_PINS_FAILURE, UPLOAD_FLOORPLAN_REQUEST, UPLOAD_FLOORPLAN_SUCCESS, UPLOAD_FLOORPLAN_FAILURE, SAVE_LABEL_REQUEST, SAVE_LABEL_SUCCESS, SAVE_LABEL_FAILURE, SAVE_FLOORPLAN_REQUEST, SAVE_FLOORPLAN_SUCCESS, SAVE_FLOORPLAN_FAILURE, GET_PDF_PAGES_REQUEST, GET_PDF_PAGES_SUCCESS, GET_PDF_PAGES_FAILURE, CLEAR_FLOORPLANS } from '../types/floorPlanTypes';
import { CLEAR_PDF_SELECTOR, SET_ACTIVE_PLAN_SUCCESS } from '../types/surveyTypes';
import _, { floor } from 'lodash';

export const loadFloorPlan = (surveyId) => dispatch => {
    try {
        if (!surveyId) {
            throw Error('No survey key');
        }
        
        dispatch({ type: LOAD_FLOORPLAN_REQUEST });

        const pins = JSON.parse(localStorage.getItem('floorPlan'));
        const plans = JSON.parse(localStorage.getItem('floorPlans'));
        let savedPins = [];
        let savedPlans = null;

        if (pins && pins[surveyId]) {
            savedPins = pins[surveyId];
        }

        if (plans && plans[surveyId]) {
            savedPlans = plans[surveyId];
        }

        dispatch({ type: LOAD_FLOORPLAN_SUCCESS, payload: { pins: savedPins, plans: savedPlans } });
        
    } catch (error) {
        dispatch({ type: LOAD_FLOORPLAN_FAILURE });
    }
}

export const setPin = (id, x, y, planRef) => dispatch => {
    let pin = {};

    if (x && y) {
        pin = { id, position: { x, y }, reference: '', planRef };
    } else {
        pin = { id, position: { x: 10, y: 10 }, reference: '', planRef };
    }

    try {
        dispatch({ type: SET_PIN_REQUEST });

        dispatch({ type: SET_PIN_SUCCESS, payload: pin });
    } catch (error) {
        dispatch({ type: SET_PIN_FAILURE, payload: error?.response?.data.errors });
    }

    const surveyId = localStorage.getItem('activeSurvey');
    let floorPlan = JSON.parse(localStorage.getItem('floorPlan'));

    if (surveyId) {
        if (floorPlan) {
            if (floorPlan[surveyId]) {
                floorPlan[surveyId].push(pin);
                localStorage.setItem('floorPlan', JSON.stringify(floorPlan));
            } else {
                let tmpPins = [];
                
                if (pin) {
                    tmpPins.push(pin);
                }

                const tmpFloorPlan = { [surveyId]: tmpPins };

                floorPlan = Object.assign(floorPlan, tmpFloorPlan);
                
                localStorage.setItem('floorPlan', JSON.stringify(floorPlan));
            }
        } else {
            let tmpPins = [];

            if (pin) {
                tmpPins.push(pin);
            }

            localStorage.setItem('floorPlan', JSON.stringify({ [surveyId]: tmpPins }));
        }
    }
}

export const updatePins = (pin) => dispatch => {
    try {
        dispatch({ type: UPDATE_PIN_REQUEST });

        dispatch({ type: UPDATE_PIN_SUCCESS, payload: pin });
    } catch (error) {
        dispatch({ type: UPDATE_PIN_FAILURE, payload: error?.response.data.errors });
    }

    const surveyId = localStorage.getItem('activeSurvey');
    let savedPins = JSON.parse(localStorage.getItem('floorPlan'));

    if (savedPins) {
        if (savedPins[surveyId]) {
            savedPins[surveyId] = [...savedPins[surveyId].filter(p => p.id !== pin.id), pin];
            localStorage.setItem('floorPlan', JSON.stringify(savedPins));
        }
    } else {
        localStorage.setItem('floorPlan', JSON.stringify({ [surveyId]: [pin] }));
    }
}

export const removePin = (id) => dispatch => {
    console.log('herer')
    try {
        dispatch({ type: REMOVE_PIN_REQUEST });

        dispatch({ type: REMOVE_PIN_SUCCESS, payload: id });
    } catch (error) {
        dispatch({ type: REMOVE_PIN_FAILURE, payload: error?.response.data.errors });
    }

    const surveyId = localStorage.getItem('activeSurvey');
    let savedPins = JSON.parse(localStorage.getItem('floorPlan'));

    if (savedPins) {
        if (savedPins[surveyId]) {
            const updatedPins = savedPins[surveyId].filter(pin => pin.id !== id);

            if (updatedPins.length > 0) {
                savedPins[surveyId] = updatedPins;
            } else {
                delete savedPins[surveyId];
            }

            if (_.isEmpty(savedPins[surveyId])) {
                if (_.isEmpty(savedPins)) {
                    localStorage.removeItem('floorPlan');
                } else {
                    localStorage.setItem('floorPlan', JSON.stringify(savedPins));
                }
            } else {
                localStorage.setItem('floorPlan', JSON.stringify(savedPins));
            }
        }
    }
} 

export const setPinRef = (id, ref) => dispatch => {
    try {
        dispatch({ type: SET_PIN_REF_REQUEST });

        dispatch({ type: SET_PIN_REF_SUCCESS, payload: { id, ref }});
    } catch (error) {
        dispatch({ type: SET_PIN_REF_FAILURE, payload: error?.response?.data.errors });
    }

    const surveyId = localStorage.getItem('activeSurvey');
    let savedPins = JSON.parse(localStorage.getItem('floorPlan'));

    if (savedPins) {
        if (savedPins[surveyId]) {
            let pin = savedPins[surveyId].find(pin => pin.id === id);
    
            if (pin) {
                pin['reference'] = ref;
                savedPins[surveyId] = [...savedPins[surveyId].filter(p => p.id !== pin.id), pin];
                localStorage.setItem('floorPlan', JSON.stringify(savedPins));
            }
        }
    }
}

export const saveLabel = (id, label) => dispatch => {
    try {
        dispatch({ type: SAVE_LABEL_REQUEST });
        dispatch({ type: SAVE_LABEL_SUCCESS, payload: { id, label } });
        
        const surveyId = localStorage.getItem('activeSurvey');
        let plans = JSON.parse(localStorage.getItem('floorPlans'));
    
        if (plans) {
            if (plans[surveyId]) {
                console.log('1');
                if (plans[surveyId][id]) {
                    console.log('2');
                    plans[surveyId][id].label = label;
                } else {
                    console.log('3');
                    const tmpPlan = {
                        [id]: {
                            id,
                            label,
                            active: false,
                        }
                    }

                    plans[surveyId] = Object.assign(plans[surveyId], tmpPlan);
                }
        
                localStorage.setItem('floorPlans', JSON.stringify(plans));
            }
        } else {
            console.log('4');
            const plans = {
                [surveyId]: {
                    [id]: {
                        id,
                        label,
                        active: false,
                    }
                }
            }
            localStorage.setItem('floorPlans', JSON.stringify(plans));
        }
    } catch (error) {
        dispatch({ type: SAVE_LABEL_FAILURE });
    }
}

export const saveFloorPlan = (id, label, surveyId) => dispatch => {
    try {
        dispatch({ type: SAVE_FLOORPLAN_REQUEST });
        dispatch({ type: SAVE_FLOORPLAN_SUCCESS, payload: { id, label } });
        
        let plans = JSON.parse(localStorage.getItem('floorPlans'));
        
        if (plans) {
            if (plans[surveyId]) {
                if (plans[surveyId][id]) {
                    plans[surveyId][id] = { ...plans[surveyId][id], active: !plans[surveyId][id].active };
                } else {
                    const tmpPlan = {
                        [id]: {
                            id,
                            label,
                            active: true,
                        }
                        
                    }
                    
                    plans[surveyId] = Object.assign(plans[surveyId], tmpPlan);
                }
                
                localStorage.setItem('floorPlans', JSON.stringify(plans));
            } else {
                plans[surveyId] = {
                    [id]: {
                        id,
                        label,
                        active: true,
                    }
                }

                localStorage.setItem('floorPlans', JSON.stringify(plans));
            }
        } else {   
            const plans = {
                [surveyId]: {
                    [id]: {
                        id,
                        label,
                        active: true,
                    }
                }
            }

            localStorage.setItem('floorPlans', JSON.stringify(plans));
        }
    } catch (error) {
        dispatch({ type: SAVE_FLOORPLAN_FAILURE });
    }
}

export const clearFloorPlans = () => dispatch => {
    dispatch({ type: CLEAR_FLOORPLANS });

    const surveyId = localStorage.getItem('activeSurvey');
    let floorPlans = JSON.parse(localStorage.getItem('floorPlans'));

    if (floorPlans) {
        if (floorPlans[surveyId]) {
            delete floorPlans[surveyId];
        }
    }

    if (_.isEmpty(floorPlans)) {
        localStorage.removeItem('floorPlans');
    } else {
        localStorage.setItem('floorPlans', JSON.stringify(floorPlans));
    }
}

export async function setPDFImage(image, name) {
    const db = new Localbase('saved-uploads');

    try {
        await db.collection('pdf-photos').add({ image }, name);
    } catch (error) {
        console.error(error);
    }
}

export const getPDFPages = (pages) => async dispatch => {
    try {
        dispatch({ type: GET_PDF_PAGES_REQUEST });

        let promises = [];

        pages.forEach((page) => {
            promises.push(axios.get('/app_handler.php', {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Wg-Image': page.image,
                    'Wg-ImageId': page.id,
                    'Wg-Method': 'GET_PDF_PAGE',
                    'Wg-Key': Cookie.get('accessToken')
                }
            }));
        });

        axios.all(promises)
            .then(axios.spread((...responses) => {
                responses.forEach(async (response, i) => {
                    const { data } = response;
                    setPDFImage(data.image, data.id);
                })
                
            }))
            .catch((error) => {
                console.error(error);
            });
        
        dispatch({ type: GET_PDF_PAGES_SUCCESS });

        if (pages.length === 1) {
            dispatch({ type: SET_ACTIVE_PLAN_SUCCESS, payload: pages[0].id });

            const surveyId = localStorage.getItem('activeSurvey');
            let activePlan = JSON.parse(localStorage.getItem('activePlan'));

            if (activePlan) {
                if (activePlan[surveyId]) {
                    activePlan[surveyId] = pages[0].id;
                } else {
                    const tmpActivePlan = {
                        [surveyId]: pages[0].id,
                    }

                    activePlan = Object.assign(activePlan, tmpActivePlan);
                }

                localStorage.setItem('activePlan', JSON.stringify(activePlan));
            } else {
                localStorage.setItem('activePlan', JSON.stringify({ [surveyId]: pages[0].id }));
            }
        }

    } catch (error) {
        dispatch({ type: GET_PDF_PAGES_FAILURE });
    }

    dispatch({ type: CLEAR_PDF_SELECTOR });
}