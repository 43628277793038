import React, { useState } from 'react';

const Input = ({ field: { type, name, placeholder, label, id, symbol, repair_label, class: fieldClass, group }, value: defaultValue, handleChange, hide, section }) => {
    const [value, setValue] = useState(defaultValue || '');

    const handleLocalChange = (e) => {
        handleChange(e);
        setValue(e.target.value);
    }

    return (
        <>
            {fieldClass === 'question-start' && (<div className={`${fieldClass} customQuestions__header`}>Other Checks for {`${section}`}</div>)}
            <div style={{ display: hide ? 'none' : 'block' }} className={`${group === 'custom_questions' ? 'input__container--inline' : ''}`}>
                <label className="form__label" htmlFor={id}>{group === 'custom_questions' ? 'Check' : label}</label>
                <div style={{ marginTop: repair_label ? '1rem' : '0' }}>
                    {repair_label && (<span className="repair_label">{repair_label}</span>)}
                    <div>
                        {symbol && (<span className="symbol">{symbol}</span>)}<input className="form__input" id={id} type={type} name={name} placeholder={placeholder} value={value} onChange={handleLocalChange} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Input;
