export const LOAD_COSTS_REQUEST = 'LOAD_COSTS_REQUEST';
export const LOAD_COSTS_SUCCESS = 'LOAD_COSTS_SUCCESS';
export const LOAD_COSTS_FAILURE = 'LOAD_COSTS_FAILURE';

export const GET_COSTS_REQUEST = 'GET_COSTS_REQUEST';
export const GET_COSTS_SUCCESS = 'GET_COSTS_SUCCESS';
export const GET_COSTS_FAILURE = 'GET_COSTS_FAILURE';

export const SET_COSTS_REQUEST = 'SET_COSTS_REQUEST';
export const SET_COSTS_SUCCESS = 'SET_COSTS_SUCCESS';
export const SET_COSTS_FAILURE = 'SET_COSTS_FAILURE';